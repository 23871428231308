<template lang="pug">
    .main-wrapper.resgate-creditos-list
        header.main-wrapper-header
            .p-grid.p-align-center
                .p-col-12.ta-right
                    h1.text-header.text-secondary MedClub / <b>Solicitações de Resgate de Créditos</b>

        Dialog.dialogDetalhesResgate(header="Detalhes da solicitação de resgate"
            :modal='true' :visible.sync='dialogResgate' :closable="!waitingSalvarResgate && !waitingComprovante")

            .p-grid.p-fluid

                .p-col-12
                    fieldset.text-small
                        legend.text-title Dados do Cliente

                        .p-grid.p-fluid.p-align-end

                            .p-col-12
                                label.form-label Nome:
                                InputText(v-model="dialogResgate_data.cd_pessoa_fisica.nm_pessoa_fisica" readonly)

                            .p-col-12.p-md-6
                                label.form-label CPF:
                                InputText(v-model="dialogResgate_data.cd_pessoa_fisica.nr_cpf_f" readonly)

                            .p-col-12.p-md-6
                                label.form-label Saldo de créditos:
                                .p-inputgroup
                                    InputText(v-model="dialogResgate_data.cd_pessoa_fisica.nr_saldo_creditos_f" readonly)
                                    a.p-button.p-button-raised(
                                        :href="`/debitos-creditos/listar?pg=1&nr_cpf=${\
                                            dialogResgate_data.cd_pessoa_fisica.nr_cpf}&ie_status=A`"
                                        v-tooltip.top="'Vizualizar'"
                                        target="_blank"
                                        style="padding:6px;")
                                        i(class='pi pi-external-link')

                            .p-col-3
                                label.form-label Cod. Banco:
                                InputText(v-model="dialogResgate_data.cd_pessoa_fisica.ds_codigo_banco" readonly)

                            .p-col-9
                                label.form-label Nome do banco:
                                InputText(v-model="dialogResgate_data.cd_pessoa_fisica.nm_banco" readonly)

                            .p-col-4.p-md-4
                                label.form-label Agência:
                                InputText(v-model="dialogResgate_data.cd_pessoa_fisica.nr_agencia" readonly)

                            .p-col-8.p-md-4
                                label.form-label Conta:
                                InputText(v-model="dialogResgate_data.cd_pessoa_fisica.nr_conta" readonly)

                            .p-col-12.p-md-4
                                label.form-label Tipo de conta:
                                InputText(v-model="dialogResgate_data.cd_pessoa_fisica.ie_tipo_conta_f" readonly)

                            .p-col-12
                                label.form-label Pix:
                                InputText(v-model="dialogResgate_data.cd_pessoa_fisica.nr_pix" readonly)

                .p-col-12.p-md-6
                    label.form-label Situação:
                    Dropdown(
                        v-model='dialogResgate_data.ie_situacao'
                        :options='options.ie_situacao'
                        optionLabel='text'
                        optionValue='value'
                        dataKey='value'
                        style="opacity: unset;"
                        disabled)

                .p-col-12.p-md-6
                    label.form-label Valor:
                    InputText(v-model="dialogResgate_data.nr_valor_f" readonly)

                .p-col-12.p-md-6(style="align-self: flex-end;")
                    ProgressBar(v-if="waitingComprovante" mode="indeterminate")
                    .p-inputgroup(v-else)
                        .p-input-icon-left.p-input-icon-right
                            InputText(
                                type="text"
                                placeholder="Anexar comprovante"
                                v-model="dialogResgate_data.nm_comprovante_f"
                                @click="$refs.refComprovante.click()"
                                readonly)
                            i.jam.jam-attachment(@click="$refs.refComprovante.click()" v-tooltip.top="'Anexar'")
                            i.jam.jam-close(
                                v-show="dialogResgate_data.aq_comprovante"
                                @click="openConfirmRemoverComprovante()"
                                v-tooltip.top="'Remover anexo'")
                        Button(
                            :disabled="! dialogResgate_data.aq_comprovante"
                            icon="pi pi-external-link"
                            v-tooltip.top="'Vizualizar comprovante'"
                            @click="imprimirComprovante()")

                    input(
                        v-show='false'
                        type="file"
                        ref="refComprovante"
                        @change="anexarComprovante()"
                        accept=".pdf")

                .p-col-12.p-md-6(:class="{ 'form-group--error': submitted && $v.dialogResgate_data.cd_forma_pagamento.$error }")
                    label.form-label Forma de Pagamento:
                    ProgressBar(v-if="waitingFormaPagamento" mode="indeterminate")
                    // InputText(v-else v-model="dialogResgate_data.cd_forma_pagamento" readonly)
                    Dropdown(v-else
                        v-model="dialogResgate_data.cd_forma_pagamento"
                        :options="options.formaPagamento"
                        optionLabel="text"
                        optionValue="value"
                        dataKey="value"
                        style="opacity: unset;"
                    )
                    .feedback--errors(v-if='submitted && $v.dialogResgate_data.cd_forma_pagamento.$error')
                        .form-message--error(v-if="!$v.dialogResgate_data.cd_forma_pagamento.required") <b>Campo obrigatório.</b>

                .p-col-12(:class="{ 'form-group--error': submitted && $v.dialogResgate_data.ds_observacao.$error }")
                    label.form-label Observação:
                    Textarea(v-model="$v.dialogResgate_data.ds_observacao.$model" rows="3" cols="30")
                    .feedback--errors(v-if='submitted && $v.dialogResgate_data.ds_observacao.$error')
                        .form-message--error(v-if="!$v.dialogResgate_data.ds_observacao.required") <b>Campo obrigatório.</b>

                .p-col-12.p-md-6
                    span <b>Usuário criação: </b> {{ dialogResgate_data.nm_usuario_cri }}
                .p-col-12.p-md-6
                    span <b>Data da criação: </b> {{ dialogResgate_data.dt_criado_f }}
                .p-col-12.p-md-6
                    span <b>Usuário edição: </b> {{ dialogResgate_data.nm_usuario_edi }}
                .p-col-12.p-md-6
                    span <b>Data da atualização: </b> {{ dialogResgate_data.dt_atualizado_f }}

            .ta-center.mt-4
                ProgressSpinner.waiting-small(v-if="waitingSalvarResgate" strokeWidth="6")
                div(v-else)
                    Button.p-button-warning.mr-1(v-if="['SO', 'FI'].includes(dialogResgate_data.ie_situacao)"
                        label="Salvar e Recusar" icon="jam jam-alert" @click="openConfirmSalvar('RE')")
                    Button.mr-1(v-if="['SO', 'RE'].includes(dialogResgate_data.ie_situacao)"
                        label="Salvar e Finalizar" icon="jam jam-check" @click="openConfirmSalvar('FI')")
                    Button.p-button-success(label="Salvar Mudanças" icon="jam jam-check" @click="salvarResgate()")

        Dialog.dialog-confirm(:showHeader="false" :visible.sync="dialogConfirm.visible"
            :modal="true" :contentStyle="{overflowY: 'auto !important'}")

            .ta-center.mt-2
                span(v-html="dialogConfirm.msg")

            .ta-right.mt-4
                Button(v-show="false")
                Button.p-button-text.mr-2(label="Não" icon="pi pi-times" @click="dialogConfirm.visible=false")
                Button.p-button-text(label="Sim" icon="pi pi-check" @click="onConfirm()")

        DialogSalvar(:display='dialogSalvar' @close="dialogSalvar=false" @created="applyFilters()")
        Panel.my-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
            .p-grid.p-fluid.p-align-end

                .p-col-12.p-md-3
                    label.form-label CPF do Solicitante:
                    .p-inputgroup
                        InputMask(
                            mask='999.999.999-99'
                            placeholder='CPF'
                            :autoClear='false'
                            v-model='filters.nr_cpf'
                            @keydown.enter="applyFilters()"
                        )
                        Button(icon='jam jam-search' @click='applyFilters()')

                .p-col-12.p-md-3
                    label.form-label Forma de Pagamento:
                    Dropdown(v-model='filters.cd_forma_pagamento' :options='options.formaPagamento'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-2
                    label.form-label Situação:
                    Dropdown(v-model='filters.ie_situacao' :options='options.ie_situacao'
                        dataKey='value' optionLabel='text' optionValue='value' placeholder='TODAS' @change='applyFilters()' )

                .p-col-12.p-md-2
                    label.form-label Data de criação:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_criado' dateFormat="dd/mm/yy" :locale="ptbr"
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_criado = null; applyFilters()')

                .p-col-12.p-md-2
                    label.form-label Data de modificação:
                    .p-inputgroup
                        Calendar( v-model='filters.dt_atualizado' dateFormat="dd/mm/yy" :locale="ptbr"
                            :manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
                        Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click='filters.dt_atualizado = null; applyFilters()')

                .p-col-12.p-md-3
                    Button(label='Limpar filtros' icon='jam jam-rubber' @click='clearFilters()')

        .ta-right.mb-2
            Button(label='Adicionar' icon="jam jam-plus" @click="dialogSalvar=true")

        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else-if='! list.length')
            p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
        div(v-else)
            DataView.dataview.my-2(:value="list" layout="grid")
                template(#grid="props")
                    .p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
                        Panel.panel-list.ta-center(:header="`Resgate #${props.data.id}`" style='position: relative')
                            .ta-left
                                p <b>Cliente: </b> {{ props.data.cd_pessoa_fisica.nm_pessoa_fisica }}
                                p <b>Observação: </b> {{ props.data.ds_observacao }}
                                p <b>Situação: </b>
                                    span(v-if="props.data.ie_situacao === 'SO'" class="highlight so") SOLICITADO
                                    span(v-if="props.data.ie_situacao === 'FI'" class="highlight fi") FINALIZADO
                                    span(v-if="props.data.ie_situacao === 'RE'" class="highlight re") RECUSADO
                                p <b>Data: </b> {{ props.data.dt_criado }}
                                p <b>Valor: </b> {{ formatPrice(props.data.nr_valor) }}
                                p <b>Forma de Pagamento: </b> {{ props.data.nm_forma_pagamento }}

                                .ta-right.mt-2
                                    Button.p-button-raised.p-button-rounded(v-tooltip.top="'Ver detalhes'"
                                        icon='jam jam-write' @click='openDialogResgate(props.data)')


            .p-grid.p-col-12.pr-0
                .p-col-4.mt-1
                    InputSwitch.mr-2(v-model='mostrarSelecionados' :disabled='!selecionados.length')
                    label.label-mostrar-selecionados Mostrar somente selecionados
                .p-col-5.ta-center.mt-1.pr-0
                        p.text-soma.ma-0(v-if='somaTotal') <b>Total Filtrado:</b> {{ formatPrice(somaTotal) }}
                        p.text-soma.ma-0.ml-2 <b>Selecionados:</b> {{ selecionados.length }}
                        p.text-soma.ma-0.ml-2 <b>Soma Selecionados:</b> {{ formatPrice(sum) }}
                        a.link-limpar.ml-2.mr-0.mt-1(v-if='sum > 0' @click='limparSelecao()') Limpar seleção
            Panel.datatable(header='Lista de Solicitações de Resgate')
                Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")
                DataTable.datatable(:value="listaDinamica" dataKey="id" class="p-datatable-striped")
                    Column(headerStyle='width: 6%;' bodyStyle='text-align: center')
                        template(#header='{data}')
                            Checkbox(v-if='mostrarSelecionados ? selecionados.length : list.length' v-model='selecionarTodos' binary)
                        template(#body='{data}')
                            Checkbox(v-model='selecionadoIds' :value='data.id' :id='data.id' @click.stop)
                    Column(headerStyle='width: 8%;' field='id' header='Cod.' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 21%;' field='cd_pessoa_fisica.nm_pessoa_fisica' header='Cliente')
                        template(#body='props')
                            span {{ props.data.cd_pessoa_fisica.nm_pessoa_fisica }}
                            span.d-block(style="font-size:10px") CPF: {{ props.data.cd_pessoa_fisica.nr_cpf_f }}
                    Column(headerStyle='width: 16%;' field='ds_observacao' header='Observação')
                    Column(headerStyle='width: 21%;' field='ds_informacoes' header='Informações')
                    Column(headerStyle='width: 14%;' header='Situação' bodyStyle='text-align:center;')
                        template(#body='props')
                            p.highlight(v-if="props.data.ie_situacao === 'SO'" :class="{so: true}") SOLICITADO
                            p.highlight(v-if="props.data.ie_situacao === 'FI'" :class="{fi: true}") FINALIZADO
                            p.highlight(v-if="props.data.ie_situacao === 'RE'" :class="{re: true}") RECUSADO
                    Column(headerStyle='width: 12%;' field='dt_criado_f' header='Dt. Criação' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 12%;' field='dt_atualizado_f' header='Dt. Modificação' bodyStyle='text-align:center;')
                    Column(headerStyle='width: 12%;' field='nr_valor_f' header='Valor' bodyStyle='text-align:right;')
                        template(#body='props')
                            span {{ props.data.nr_valor_f }} <br>
                            span <b> {{ dominio.formaPagamento[props.data.nm_forma_pagamento] }} </b>
                    Column(headerStyle='width: 9%;' bodyStyle='text-align: center;' header='Ações' headerClass='box-hide' bodyClass='box-hide')
                        template(#body='props')
                            Button.p-button-raised.p-button-rounded(v-tooltip.top="'Ver detalhes'"
                                icon='pi pi-external-link' @click='openDialogResgate(props.data)')

                Paginator.mb-1(:rows='paginadorDinamico.rows' :first='paginadorDinamico.first' :totalRecords='paginadorDinamico.total' @page="paginadorDinamico.onPage")

</template>

<style lang="scss">
.resgate-creditos-list {
    .dataview {
        @media all and (min-width: 577px) {
            display: none;
        }
    }
    .datatable {
        @media all and (max-width: 576px) {
            display: none;
        }
        .cell {
            padding: 16px 0;
            text-align: center;
            &.ex {
                background-color: #e4f8e1;
            }
            &.a {
                background-color: #faf3dd;
            }
        }
        .p-progress-spinner {
            max-width: 23px;
            height: auto;
        }
    }
    .dialog-confirm {
        width: 300px;
        .p-dialog-content {
            padding-bottom: 6px;
        }
        .p-button.p-button-text {
            background-color: transparent;
            color: #2196f3;
            border-color: transparent;

            &:hover {
                border-color: #2196f3;
            }
        }
    }
    .dialogDetalhesResgate {
        width: 98%;
        max-width: 750px;
    }
    .highlight {
        background-color: #c9daf8;
        display: inline;
        font-weight: 700;

        &.fi {
            background-color: #c5efcb;
        }
        &.re {
            background-color: #f8d6d9;
        }
        &.so {
            background-color: #fff2cc;
        }
    }
    .text-small {
        .p-inputtext {
            font-size: 12px;
        }
        .form-label {
            font-size: 12px;
        }
    }
    .fieldset {
        border: 1px solid #c8c8c8;
    }
    .text-title {
        color: #657786;
        font-size: 14px;
        font-weight: 700;
    }
    .p-tooltip {
        max-width: none;
    }
    .waiting-small {
        max-width: 29px;
        height: auto;
    }
    .form-group--error .p-inputtextarea {
        border-color: #db5540;
    }
    .p-input-icon-left,
    .p-input-icon-right {
        position: relative;
        display: inline-block;
        width: 100%;
    }
    .p-input-icon-left > i,
    .p-input-icon-right > i {
        position: absolute;
        top: 50%;
        margin-top: -0.5rem;
        cursor: pointer;
    }
    .p-input-icon-left > i:first-of-type {
        left: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-right > i:last-of-type {
        right: 0.5rem;
        color: #6c757d;
    }
    .p-input-icon-left > .p-inputtext {
        padding-left: 2rem;
    }
    .p-input-icon-right > .p-inputtext {
        padding-right: 2rem;
    }
    .link-limpar {
        text-decoration: underline;
        font-size: 12px;
        cursor: pointer;
        font-weight: 700;
        margin-right: 10px;
        display: inline-block;
        margin-bottom: 6px;
    }
    .text-soma {
        margin-bottom: 4px;
        margin-right: 10px;
        display: inline-block;
        background-color: #faf3dd;
        box-shadow: 0 1px 2px #ddd;
        padding: 6px 10px;
    }
    .label-mostrar-selecionados {
        vertical-align: super;
        font-size: 12px;
        font-weight: 550;
    }
}
</style>

<script>
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import DataView from "primevue/dataview";
import Dialog from "primevue/dialog";
import Dropdown from "primevue/dropdown";
import InputMask from "primevue/inputmask";
import InputText from "primevue/inputtext";
import Paginator from "primevue/paginator";
import Panel from "primevue/panel";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import Tooltip from "primevue/tooltip";
import wsConfigs from "@/middleware/configs";
import { Caixas } from "@/middleware";
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { pCalendarLocale_ptbr } from "@/utils";
import DialogSalvar from "./DialogSalvar";
import { DominioValor } from "../../middleware";
import Checkbox from "primevue/checkbox";
import InputSwitch from "primevue/inputswitch";
import * as _ from 'lodash'

export default {
    components: {
        Button,
        Calendar,
        Column,
        DataTable,
        DataView,
        Dialog,
        DialogSalvar,
        Dropdown,
        InputText,
        InputMask,
        Paginator,
        Panel,
        ProgressBar,
        ProgressSpinner,
        Textarea,
        Checkbox,
        InputSwitch
    },
    directives: { tooltip: Tooltip },
    created() {
        this.applyFilters();
        this.getFormaPagamento();
    },
    data() {
        return {
            submitted: false,
            waiting: false,
            windowInnerWidth: window.innerWidth,
            ptbr: pCalendarLocale_ptbr,
            waitingSalvarResgate: false,
            selecionados: [],
            selecionadoIds: [],
            itensIds: [],
			mostrarSelecionados: false,
			somaTotal: 0,
            dialogResgate: false,
            dialogResgate_data: {
                cd_pessoa_fisica: {},
                cd_forma_pagamento: null,
            },
            waitingComprovante: false,
            waitingFormaPagamento: false,
            comprovante: {},
            dialogConfirm: {
                visible: false,
                msg: "",
                funcCallback: null,
                args: []
            },
            dialogSalvar: false,
            list: [],
            paginator: {
                page: this.$route.query.pg || 1,
                per_page: wsConfigs.paginator_perPage,
                count: 0
            },
            paginadorDinamico: {
                rows: wsConfigs.paginator_perPage,
                first: 0,
                total: 0,
                page: this.$route.query.pg || 1,
                onPage: null
            },
            order: {
                field: "dt_criado",
                sortOrder: -1
            },
            filters: {
                nr_cpf: null,
                ie_situacao: null,
                dt_criado: null,
                dt_atualizado: null,
                cd_forma_pagamento: null,
            },
            options: {
                ie_situacao: [
                    { value: null, text: "TODAS" },
                    { value: "SO", text: "Solicitado" },
                    { value: "FI", text: "Finalizado" },
                    { value: "RE", text: "Recusado" },
                    { value: "CA", text: "Cancelado" }
                ],
                formaPagamento: [],
            },
            dominio: {
                formaPagamento: []
            }
        };
    },
    watch: {
        'selecionadoIds': function(val) {
            if(this.selecionadoIds.length < this.selecionados.length) {
                this.selecionados = _.filter(this.selecionados, s => this.selecionadoIds.includes(s.id))
            } else {
                this.selecionados = _.uniqBy(_.union(this.selecionados, _.filter(this.list, l => this.selecionadoIds.includes(l.id))), 'id')
            }

            if (!val.length)
            this.mostrarSelecionados = false
            else if (this.mostrarSelecionados)
            this.paginadorDinamico['total'] = val.length
        },
        mostrarSelecionados: function() {
            this.atualizaPaginacao()
        },
    },
    computed: {
        selecionarTodos: {
            get() {
                return this.mostrarSelecionados || this.selecionadoIds.length && _.every(this.itensIds, (v) => this.selecionadoIds.includes(v))
            },
            set(val) {
                if(val) {
                    this.selecionadoIds = _.union(this.selecionadoIds, this.itensIds)
                } else {
                    this.selecionadoIds = this.mostrarSelecionados ? [] : _.xor(this.selecionadoIds, this.itensIds)
                }
            }
        },
        sum: function() {
            
            if (!this.selecionados) return 0
            return this.selecionados.reduce((total, item) => total + parseFloat(item.nr_valor), 0)
            
        },
        listaDinamica: function() {
            if (this.mostrarSelecionados) {
                const reagrupa = _.groupBy(this.selecionados, (v) => _.toInteger((_.indexOf(this.selecionados, v))/this.paginadorDinamico.rows))
                return reagrupa[this.paginadorDinamico.page] 
            } else
                return this.list
        }
    },
    validations() {
        return {
            dialogResgate_data: {
                ds_observacao: { required },
                cd_forma_pagamento: { required },
            }
        };
    },
    methods: {
        getFormaPagamento () {
            this.waitingFormaPagamento = true;
            DominioValor.findAll({ds_mnemonico: 'FORMA_PAGAMENTO_RESGATE'}).then(response => {
                if (response.status === 200) {
                    this.options.formaPagamento = [{ value: null, text: "TODAS" },]
                    this.dominio.formaPagamento = []
                    response.data['FORMA_PAGAMENTO_RESGATE'].valores.forEach(i => {
                        this.options.formaPagamento.push({
                            value: parseInt(i.ie_valor),
                            text: i.ds_valor,
                        })
                        this.dominio.formaPagamento[i.ie_valor] = i.ds_valor
                    })
                    this.waitingFormaPagamento = false;
                } else {
                    this.$toast.error("Problema ao carregar as formas de pagamento.", { duration: 3000 });
                }
            })
        },
        formatPrice(val) {
            return new Intl.NumberFormat("pt-BR", {
                style: "currency",
                currency: "BRL"
            }).format(val);
        },
        formatFileName(name) {
            if (name.length < 30) return name;
            return name.substr(0, 20) + "..." + name.substr(name.length - 8);
        },
        onPage(ev) {
            this.paginadorDinamico.page = ev.page + 1
            this.applyFilters(this.paginadorDinamico.page);
        },
        onConfirm() {
            this.dialogConfirm.funcCallback(...this.dialogConfirm.args);
            this.dialogConfirm = {
                visible: false,
                msg: "",
                funcCallback: null,
                args: []
            };
        },
        openDialogResgate(resgate) {
            this.dialogResgate_data = resgate;
            this.dialogResgate = true;
        },
        openConfirmSalvar(ieStatus) {
            this.dialogConfirm.args = [ieStatus];
            this.dialogConfirm.msg = `Deseja mesmo <b>${
                ieStatus === "FI" ? "APROVAR" : "RECUSAR"
            }</b> esta solicitação de resgate?`;
            this.dialogConfirm.funcCallback = this.salvarResgate;
            this.dialogConfirm.visible = true;
        },
        openConfirmRemoverComprovante() {
            this.dialogConfirm.msg = `Deseja mesmo <b>REMOVER</b> o comprovante anexado?`;
            this.dialogConfirm.funcCallback = this.removerComprovante;
            this.dialogConfirm.visible = true;
        },
        clearFilters() {
            this.paginatorDinamico.page = 1
            Object.keys(this.filters).forEach(k => (this.filters[k] = null));
            this.applyFilters();
        },
        applyFilters(page) {
            const p = page || 1
                
            this.paginadorDinamico.page = p
            this.paginator.page = p

            let params = { paginacao: true, page: this.paginadorDinamico.page, per_page: this.paginadorDinamico.rows }
            params.order = `${this.order.sortOrder === -1 ? "-" : ""}${
                this.order.field
            }`;

            if (this.$route.query.pg != this.paginator.page)
                this.$router.replace({
                    query: { pg: this.paginator.page.toString() }
                });

            Object.keys(this.filters).forEach(key => {
                if (this.filters[key]) {
                    if (key === "nr_cpf")
                        params[key] = this.filters[key].replace(/[^0-9]+/g, "");
                    else if (key.substr(0, 3) === "dt_")
                        params[key] = moment(this.filters[key]).format(
                            "YYYY-MM-DD"
                        );
                    else params[key] = this.filters[key];
                }
            });

            this.getList(params);
        },
        getList(params) {
            this.waiting = true;
            Caixas.findAllResgateCreditos(params).then(response => {
                this.waiting = false;
                if (response.status === 200) {
                    this.paginator.count = response.data.count;
                    this.paginadorDinamico.total = response.data.count
                    this.list = response.data.results;
                    this.itensIds = []
                    this.list.forEach(resgate => {
                        resgate.dt_criado_f = resgate.dt_criado
                            ? moment(resgate.dt_criado).format(
                                "DD/MM/YYYY HH:mm"
                            )
                            : "";
                        resgate.dt_atualizado_f = resgate.dt_atualizado
                            ? moment(resgate.dt_atualizado).format(
                                "DD/MM/YYYY HH:mm"
                            )
                            : "";
                        resgate.nr_valor_f = this.formatPrice(resgate.nr_valor);
                        resgate.cd_pessoa_fisica.nr_cpf_f = resgate.cd_pessoa_fisica.nr_cpf.replace(
                            /(\d{3})(\d{3})(\d{3})(\d{2})/,
                            "$1.$2.$3-$4"
                        );
                        resgate.cd_pessoa_fisica.nr_saldo_creditos_f = this.formatPrice(
                            resgate.cd_pessoa_fisica.nr_saldo_creditos
                        );
                        resgate.cd_pessoa_fisica.ie_tipo_conta_f =
                            "Conta Poupança";
                        if (resgate.cd_pessoa_fisica.ie_tipo_conta === "CC") {
                            resgate.cd_pessoa_fisica.ie_tipo_conta_f =
                                "Conta Corrente";
                        }
                        if (resgate.nm_comprovante)
                            resgate.nm_comprovante_f = this.formatFileName(
                                resgate.nm_comprovante
                            );

                        this.itensIds.push(resgate.id)
                    });
                }
                this.atualizaPaginacao()
            });
        },
        salvarResgate(ieSituacao) {
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                this.$toast.error("Verifique os dados e tente novamente.");
                return;
            }

            let dataSend = {};
            dataSend.id = this.dialogResgate_data.id;
            dataSend.ds_observacao = this.dialogResgate_data.ds_observacao;
            dataSend.cd_forma_pagamento = this.dialogResgate_data.cd_forma_pagamento;
            if (ieSituacao) dataSend.ie_situacao = ieSituacao;

            this.waitingSalvarResgate = true;
            Caixas.salvarResgateCreditos(dataSend).then(response => {
                this.waitingSalvarResgate = false;
                if ([200, 201].includes(response.status)) {
                    this.$toast.success("Salvo com sucesso.", {
                        duration: 3000
                    });
                    this.dialogResgate = false;
                    this.applyFilters();
                } else if (response.data.detail) {
                    if (typeof response.data.detail == "string")
                        this.$toast.error(response.data.detail, {
                            duration: 3000
                        });
                    else
                        response.data.detail.forEach(msg =>
                            this.$toast.error(msg, { duration: 3000 })
                        );
                }
                this.submitted = false;
            });
        },
        anexarComprovante() {
            const files = this.$refs.refComprovante.files;
            const comprovante = files.length && files[0];
            if (!comprovante) return;

            this.waitingComprovante = true;
            Caixas.anexarComprovanteResgate(
                this.dialogResgate_data.id,
                comprovante
            ).then(response => {
                this.waitingComprovante = false;
                if (response.status === 200) {
                    this.dialogResgate_data.nm_comprovante_f = this.formatFileName(
                        comprovante.name
                    );
                    this.dialogResgate_data.aq_comprovante =
                        response.data.aq_comprovante;
                    this.$toast.success(
                        "Comprovante de transferencia ANEXADO com sucesso."
                    );
                } else {
                    this.toastResponseDetail(response.data.detail);
                }
            });
        },
        removerComprovante() {
            this.waitingComprovante = true;
            Caixas.removerComprovanteResgate(this.dialogResgate_data.id).then(
                response => {
                    this.waitingComprovante = false;
                    if ([200, 201, 204].includes(response.status)) {
                        this.dialogResgate_data.nm_comprovante_f = "";
                        this.dialogResgate_data.aq_comprovante = null;
                        this.$refs.refComprovante.value = "";
                        this.$toast.success(
                            "Comprovante de transferencia REMOVIDO com sucesso."
                        );
                    } else {
                        this.toastResponseDetail(response.data.detail);
                    }
                }
            );
        },
        imprimirComprovante() {
            if (this.dialogResgate_data.aq_comprovante)
                window.open(this.dialogResgate_data.aq_comprovante);
            else this.$toast.error("Link para o arquivo não encontrado.");
        },
        toastResponseDetail(detail) {
            if (!!detail && typeof detail == "string")
                this.$toast.error(detail, { duration: 3000 });
            else if (typeof detail == "object")
                detail.forEach(msg =>
                    this.$toast.error(msg, { duration: 3000 })
                );
        },
        limparSelecao () {
			this.selecionadoIds = []
		},
        atualizaPaginacao () {
                this.paginadorDinamico['page'] = this.mostrarSelecionados 
                                            ? 0 
                                            : this.paginator.page  || 1
                ;

                [	
                    this.paginadorDinamico['first'],
                    this.paginadorDinamico['total'],
                    this.paginadorDinamico['onPage'],
                ] = this.mostrarSelecionados ? [
                    0,
                    this.selecionados.length,
                    ({ page })  => {
                        this.paginadorDinamico.page = page;
                        this.paginadorDinamico.first = this.paginadorDinamico.page * this.paginadorDinamico.rows;
                    },
                ] : [
                    ((this.paginadorDinamico.page - 1) * this.paginadorDinamico.rows),
                    this.paginator.count,
                    this.onPage,
                ]
            }
    }
};
</script>
