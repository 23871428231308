<template>
    <Dialog
        class="dialog-salvar-resgate"
        header="Criar Resgate"
        :modal="true"
        :visible.sync="show"
        :closable="!waitingSalvar"
    >
        <div class="p-grid p-fluid">
            <div :class="[{ 'form-group--error': submitted && $v.model.cd_pessoa_fisica.$error }, 'p-col-12']">
                <BuscarPaciente
                    ref='buscarPaciente'
                    class="buscar-cliente"
                    label='Cliente'
                    :somenteTitular='true'
                    :hideCadastro='true'
                    :cancelarPopup='true'
                    @change="getSaldo" />
                <div class="feedback--errors" v-if="submitted && $v.model.cd_pessoa_fisica.$error">
                    <div class="form-message--error" v-if="!$v.model.cd_pessoa_fisica.required">Campo obrigatório.</div>
                </div>
            </div>

            <div class="p-col-12 p-sm-4">
                <label class="form-label">Saldo do Cliente:</label>
                <ProgressBar v-if='waitingSaldo' mode="indeterminate" />
                <CurrencyInput v-else v-model="saldoCliente" disabled />
            </div>

            <div :class="[{ 'form-group--error': submitted && $v.model.nr_valor.$error }, 'p-col-12 p-sm-4']">
                <label class="form-label">Valor do Resgate:</label>
                <CurrencyInput v-model="$v.model.nr_valor.$model" />
                <div class="feedback--errors" v-if="submitted && $v.model.nr_valor.$error">
                    <div class="form-message--error" v-if="!$v.model.nr_valor.minValue">
                        Valor mínimo: <b>R$ 10,00</b>.
                    </div>
                    <div class="form-message--error" v-if="!$v.model.nr_valor.maxValue">
                        Valor máximo: <b>R$ {{ saldoCliente }}</b>.
                    </div>
                </div>
            </div>

            <div :class="[{ 'form-group--error': submitted && $v.model.cd_forma_pagamento.$error }, 'p-col-12 p-sm-4']">
                <label class="form-label">Forma de Pagamento:</label>
                <ProgressBar v-if="waitingFormaPagamento" mode="indeterminate" />
                <div v-else>
                    <Dropdown
                        v-model="model.cd_forma_pagamento"
                        :options="options.formaPagamento"
                        optionLabel="text"
                        optionValue="value"
                        dataKey="value" />
                    <div class="feedback--errors" v-if="submitted && $v.model.cd_forma_pagamento.$error">
                        <div class="form-message--error" v-if="!$v.model.cd_forma_pagamento.required">
                            <b>Forma de pagamento</b> é obrigatório
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-col-12">
                <label class="form-label">Observação:</label>
                <Textarea v-model="model.ds_observacao" rows="3" cols="30" />
            </div>
        </div>

        <div class="ta-center mt-4">
            <ProgressSpinner class="waiting-small" v-if="waitingSalvar" strokeWidth="6" />
            <div v-else>
                <Button class="p-button-secondary mr-1" label="Cancelar" icon="jam jam-close" @click="show=false" />
                <Button class="p-button" label="Salvar" icon="jam jam-check" @click="salvarResgate()" />
            </div>
        </div>
    </Dialog>
</template>

<script>
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import ProgressBar from "primevue/progressbar";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import Dropdown from "primevue/dropdown";

import BuscarPaciente from "@/components/Paciente/BuscarPaciente";
import CurrencyInput from "@/components/CustomComponents/CurrencyInput";

import {Caixas, Paciente} from "@/middleware";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import { DominioValor } from "../../middleware";

export default {
    components: { BuscarPaciente, CurrencyInput, Button, Dialog, ProgressBar, ProgressSpinner, Textarea, Dropdown, },
    props: { display: Boolean },
    computed: {
        show: {
            get() { 
                if(this.display) this.getFormaPagamento()
                return this.display; 
            },
            set(value) { if (!value) this.close(); }
        }
    },
    data() {
        return {
            model: {
                cd_pessoa_fisica: null,
                nr_valor: 0,
                ds_observacao: "",
                cd_forma_pagamento: null,
            },
            options: {
                formaPagamento: [],
            },
            saldoCliente: 0,
            submitted: false,
            waitingSalvar: false,
            waitingSaldo: false,
            waitingFormaPagamento: false
        }
    },
    validations () {
        return {
            model: {
                cd_pessoa_fisica: { required },
                nr_valor: { minValue: minValue(10), maxValue: maxValue(this.saldoCliente) },
                cd_forma_pagamento: { required },
            }
        }
    },
    methods: {
        getFormaPagamento () {
            this.waitingFormaPagamento = true;
            DominioValor.findAll({ds_mnemonico: 'FORMA_PAGAMENTO_RESGATE'}).then(response => {
                if (response.status === 200) {
                    this.waitingFormaPagamento = false;
                    this.options.formaPagamento = []
                    response.data['FORMA_PAGAMENTO_RESGATE'].valores.forEach(i => {
                        this.options.formaPagamento.push({
                            value: parseInt(i.ie_valor),
                            text: i.ds_valor,
                        })
                    })
                } else {
                    this.$toast.error("Problema ao carregar as formas de pagamento.", { duration: 3000 });
                }
            })
        },
        salvarResgate() {
            this.model.cd_pessoa_fisica = this.$refs.buscarPaciente.cd_pessoa_fisica;

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) return;

            const dataSend = Object.assign({}, this.model);
            this.waitingSalvar = true;
            Caixas.salvarResgateCreditos(dataSend).then(response => {
                this.waitingSalvar = false;
                if ([200, 201].includes(response.status)) {
                    this.$emit('created', response.data);
                    this.show = false;
                } else if (response.status === 400) {
                    if (response.data.detail) {
                        if (typeof response.data.detail == 'string') {
                            this.$toast.error(response.data.detail, { duration: 3000 });
                        } else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }));
                    } else if (response.data){
                        Object.values(response.data).forEach(error => {
                            error.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        })
                    }
                } else {
                    this.$toast.error("Houve um problema ao salvar!", { duration: 3000 });
                }
            });
        },
        getSaldo(cd_pessoa_fisica) {
            this.model.cd_pessoa_fisica = cd_pessoa_fisica;
            this.saldoCliente = 0;

            if (!cd_pessoa_fisica) return;

            this.waitingSaldo = true;
            Paciente.saldoPaciente(cd_pessoa_fisica).then(response => {
                this.waitingSaldo = false;
                if (response.status === 200) {
                    this.saldoCliente = response.data.saldo || 0;
                }
            });
        },
        close() {
            this.model = { cd_pessoa_fisica: null, nr_valor: 0, ds_observacao: "" };
            this.saldoCliente = 0;
            this.submitted = false;
            this.$emit('close');
        },
    }
}
</script>

<style lang="scss">
.dialog-salvar-resgate {
    width: 700px;
    max-width: 98%;
}
</style>
